<template>
  <div class="wrap">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="box xshy">
          <div class="t clearfix bbd pr10">
            <div class="tit fl">重要会议</div><a @click="goHYlist" class="more">更多&gt;&gt;</a>
          </div>
          <div class="c">
            <ul class="dotlist lh18">
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('33')">共谋急诊影像质控新发展 “2024-08-28”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('31')">国家级放射影像专业质控中心X线技术亚专业组第二次会议成功举行 “2024-08-27”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('29')">国家级放射影像专业质控中心工作会议 “2024-08-09”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('27')">青岛市放射影像质控中心2024年危急值专题讨论会 “2024-08-06”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false"
                  @click="onClick('25')">自治区放射专业质控中心工作会议及放射质量控制能力提高学习班在新医大一附院举办（转载）“2024-07-17”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('23')">【河南省放射影像质控中心2024年第一次专题培训会议圆满召开（转载）“2024-06-22”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('21')">【协和帮扶】贵州省放射影像专业质控中心2024年上半年工作会召开（转载）“2024-07-09”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('19')">共筑医疗质控健康防线，推动放射影像高质发展
                  “2024-06-25”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('17')">2024年度河北省医学影像质控中心工作会议
                  成功举办
                  “2024-05-25”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('15')">2024年国家级放射影像专业质控中心亚专业组组长第一次会议
                  成功召开
                  “2024-05-11”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('13')">严把医疗质量关，筑牢生命安全线—2024年广东省放射影像专业质控中心工作会议简报
                  “2024-04-20”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('11')">2024年国家级放射影像专业质控中心深圳工作会议
                  “2024-04-18”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('10')">吉林省医学影像科质控中心成功举办2024年度吉林省医学影像科质控中心会议
                  “2024-04-12”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('9')">国家放射影像专业质控中心专题讲座及2024年国家级放射影像专业质控中心专家委员会第一次工作会议成功举办
                  “2024-03-29”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('7')">黑龙江省放射影像专业质控中心专家委员会工作交流会胜利召开 “2024-03-30”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('5')">贵州省放射影像专业质控中心“基于病例的质控培训”第二期成功举办 “2024-03-26”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('3')">2024年山东省医学影像质控中心工作会议简报 “2024-03-08”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('1')">影像联盟 和衷共济（转载）“2024-01-06”
                </el-link>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box xshy">
          <div class="t clearfix bbd pr10">
            <div class="tit fl">重要通知</div><a class="more" @click="goTZlist">更多&gt;&gt;</a>
          </div>

          <div class="c">
            <ul class="dotlist lh18">
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('4')">国家卫生健康委办公厅关于印发急诊医学等6个专业医疗质量控制指标（2024年版）的通知
                  “2024-05-29”
                </el-link>
              </li>
              <li class="ellipsis cus_link">
                <el-link :underline="false" @click="onClick('2')">关于召开2024年山东省医学影像学质量控制中心工作会议的通知 “2024-01-26”</el-link>
              </li>
            </ul>
          </div>

        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "HYXW",
  data() {
    return {
    }
  },
  methods: {
    onClick(seq) {
      if (seq == '1') {
        this.$router.push('/XSJX')
      } else if (seq == '2') {
        this.$router.push('/XW2')
      } else if (seq == '3') {
        this.$router.push('/SDSHYJB')
      } else if (seq == '5') {
        this.$router.push('/GZSPX')
      } else if (seq == '7') {
        this.$router.push('/HLJZJH')
      } else if (seq == '9') {
        this.$router.push('/GJJ2024')
      } else if (seq == '10') {
        this.$router.push('/JLS2024')
      } else if (seq == '11') {
        this.$router.push('/SZ2024')
      } else if (seq == '13') {
        this.$router.push('/GDS2024')
      } else if (seq == '4') {
        this.$router.push('/YLZLKZBZ2024')
      } else if (seq == '15') {
        this.$router.push('/HY20240622')
      } else if (seq == '17') {
        this.$router.push('/HY20240623')
      } else if (seq == '19') {
        this.$router.push('/HY20240701')
      } else if (seq == '21') {
        this.$router.push('/HY20240712')
      } else if (seq == '23') {
        this.$router.push('/HY20240723')
      } else if (seq == '25') {
        this.$router.push('/HY20240724')
      } else if (seq == '27') {
        this.$router.push('/HY20240812')
      } else if (seq == '29') {
        this.$router.push('/HY20240815')
      } else if (seq == '31') {
        this.$router.push('/HY20240827')
      } else if (seq == '33') {
        this.$router.push('/HY20240828')
      }
    },
    goHYlist() {
      this.$router.push('/HY_list')
    },
    goTZlist() {
      this.$router.push('/TZ_list')
    },
  }
}
</script>

<style scoped>
.wrap {
  width: 100%;
  margin: 0 auto;
}

.box {
  min-height: 600px;
  background-color: #fff;
}

.box .t {
  line-height: 40px;
  min-height: 40px;
  height: auto !important;
  height: 40px;
  overflow: visible;
  display: flex;
  justify-content: space-between;
}

.bbd {
  border-bottom: 1px solid #eee;
}

.box .t .tit {
  height: 16px;
  line-height: 16px;
  color: #2b4a8f;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
  /* background: url(../images/boxt.png) left center no-repeat; */
  margin-top: 12px;
}

a.more,
a.more:link,
a.more:visited {
  font-weight: normal;
  float: right;
  color: #777;
}

.pr10 {
  padding-right: 10px;
}

.lh18 {
  line-height: 1.8;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

ul,
li {
  list-style-type: none;
}

.dotlist li {
  /* background: url(../images/dot.png) left center no-repeat; */
  padding: 5px 0px;
  padding-left: 10px;
}

.ellipsis {}

.el-link::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #777;
  margin-right: 8px;
  vertical-align: middle;
}


a,
a:link {
  color: #777;
  transition: all 0.3s;
}

a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.cus_link {
  margin-top: 20px;
}

.cus_link>>>.el-link {
  font-size: 16px;
}

.cus_link>>>.el-link {
  /* text-overflow: ellipsis; */
  white-space: normal;
  overflow: hidden;
  display: block;
}
</style>